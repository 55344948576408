// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/CgpwDOq5m";

const enabledGestures = {VrIJWm2lH: {hover: true}};

const cycleOrder = ["VrIJWm2lH"];

const variantClassNames = {VrIJWm2lH: "framer-v-ormo0p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {"VrIJWm2lH-hover": {damping: 60, delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "tween"}, default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "VrIJWm2lH", title: KFQCA4XQi = "Még több", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VrIJWm2lH", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-CXA9f", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Link href={{webPageId: "Kz0oTMDWl"}} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-ormo0p", className)} framer-eazmei`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VrIJWm2lH"} ref={ref} style={{backgroundColor: "rgb(51, 170, 255)", borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, ...style}} transition={transition} variants={{"VrIJWm2lH-hover": {backgroundColor: "rgb(9, 12, 22)"}}} {...addPropertyOverrides({"VrIJWm2lH-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-1spua54"} data-styles-preset={"CgpwDOq5m"}>Még több</motion.h3></React.Fragment>} className={"framer-rrhcha"} layoutDependency={layoutDependency} layoutId={"CmJn8Eg7h"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={KFQCA4XQi} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-CXA9f [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CXA9f * { box-sizing: border-box; }", ".framer-CXA9f .framer-eazmei { display: block; }", ".framer-CXA9f .framer-ormo0p { height: 47px; overflow: hidden; position: relative; text-decoration: none; width: 157px; will-change: transform; }", ".framer-CXA9f .framer-rrhcha { flex: none; height: auto; left: 49%; position: absolute; top: 51%; white-space: pre; width: auto; }", ".framer-CXA9f .framer-v-ormo0p .framer-ormo0p { cursor: pointer; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 47
 * @framerIntrinsicWidth 157
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Gb_erU7Rp":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"KFQCA4XQi":"title"}
 */
const Framerb3Ssz_PCe: React.ComponentType<Props> = withCSS(Component, css, "framer-CXA9f") as typeof Component;
export default Framerb3Ssz_PCe;

Framerb3Ssz_PCe.displayName = "Gomb";

Framerb3Ssz_PCe.defaultProps = {height: 47, width: 157};

addPropertyControls(Framerb3Ssz_PCe, {KFQCA4XQi: {defaultValue: "Még több", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerb3Ssz_PCe, [...sharedStyle.fonts])